<template lang="pug">
formTitle Recuperar contraseña
.flex.flex-col.mt-6.mb-4.gap-6
  inputText(labelText="Correo electronico", placeholder="ejemplo@mail.com")
  ctaButton(textButton="Recuperar")
  .link(@click="$emit('changeTab', 'Access')") Iniciar sesión
</template>
<script>
export default {
  name: "remember",
  emits: ["changeTab"],
};
</script>
<style lang="scss" scoped>
.link {
  @apply text-center text-sm cursor-pointer font-medium text-gray-400;
  &:hover {
    @apply text-green-700;
  }
}
</style>